/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import {
    Stack,
    Typography,
    Box,
    Divider
} from '@mui/material';
import UIText from '../../utils/TextResources';

const Supplier = ({ messages }) => {
    let filesList;
    if (messages) {
        filesList = messages.files;
        //console.log("fileList: ", filesList);
    }

    const getFileExtension = (filename) => {
        return filename.split('.').pop();
    };

    return (
        <>
            {messages && (
                <Stack>
                    <Typography sx={{ paddingTop: "6%", color: "#959595" }}>{UIText.files.filesList}</Typography>

                    {filesList.map((file, index) => (
                        <Stack key={index} sx={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
                            <Box
                                sx={{
                                    height: "32px",
                                    width: "46px",
                                    borderRadius: "20px",
                                    backgroundColor: "#ebebeb",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "16px"
                                }}
                            >
                                {getFileExtension(file.name)}
                            </Box>

                            {/* Wrap file name in an anchor tag for downloading */}
                            <Typography
                                component="a"
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    color: "#7DC3FE",
                                    paddingLeft: "8px",
                                    paddingTop: "4px",
                                    textDecoration: "none",
                                    "&:hover": {
                                        color: "#469feb",
                                        cursor: "pointer",
                                    }
                                }}
                            >
                                {file.name.substring(file.name.lastIndexOf("/") + 1)}
                            </Typography>
                        </Stack>
                    ))}

                    {filesList && filesList.length === 0 && (
                        <Typography sx={{ paddingTop: "8px", color: "#7F7F7F", fontSize: "14px" }}>{UIText.files.notFiles}</Typography>
                    )}
                    <Divider sx={{ maxWidth: "98%", paddingTop: "22px" }} />
                </Stack>
            )}
        </>
    );
}

export default Supplier;
