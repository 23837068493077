/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Stack, Avatar, Typography, Box, Divider } from '@mui/material';
import UIText from '../../utils/TextResources';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BoltIcon from '@mui/icons-material/Bolt';

const Footer = ({ messages }) => {
    const [showHistory, setShowHistory] = useState(true);

    const toggleHistory = () => {
        setShowHistory(prevState => !prevState);
    };

    let messagesData = messages.messages;
    const formatDate = (createdAt) => {
        const options = { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
        const formattedDate = new Date(createdAt).toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <>
            <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingTop: "6px" }}>
                <Typography sx={{ fontWeight: "bold" }}>{UIText.footer.active}</Typography>
                {showHistory ? (
                    <KeyboardArrowUpIcon sx={{ cursor: "pointer" }} onClick={toggleHistory} />
                ) : (
                    <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} onClick={toggleHistory} />
                )}
            </Stack>

            {showHistory && messages && (
                <>
                    {messagesData.length > 0 ? (
                        messagesData.map((message, index) => (
                            <Stack key={index} sx={{ marginTop: "4%" }}>
                                <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Avatar alt={message.author_name} src={message.author_picture} />
                                        <Typography sx={{ padding: "10px", fontSize: "12px" }}>{message.author_name}</Typography>
                                    </Box>
                                </Stack>
                                <Typography sx={{ fontSize: "12px", paddingTop: "10px" }}>{formatDate(message.created_at)}</Typography>
                                <Typography sx={{ fontSize: "16px", fontWeight: "500", paddingTop: "10px", overflow: "auto" }}>{message.body}</Typography>
                                {message.event_msg && (
                                    <Stack direction="row" sx={{ paddingTop: "10px" }}>
                                        <BoltIcon sx={{ fontSize: "18px" }} />
                                        <Typography sx={{ fontSize: "12px" }}>{message.event_msg}</Typography>
                                    </Stack>
                                )}
                                {index < messagesData.length - 1 && (
                                    <Divider sx={{ maxWidth: "98%", paddingTop: "22px" }} />
                                )}
                            </Stack>
                        ))
                    ) : (
                        // Show message if history not found  
                        <Typography sx={{ paddingTop: "8px", color: "#7F7F7F", fontSize: "14px" }}>{UIText.footer.noHistory}</Typography>
                    )}
                </>
            )}
        </>
    )
}

export default Footer;