/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Stack, TextField, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { SearchList } from '../../services/SearchList';
import UserInfo from '../../utils/UsersInfo';
import { Token } from '../../utils/accessToken';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const Search = ({ handleSelectedObjectId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showSearchList, setShowSearchList] = useState(false);
    const searchList = useRef(null);
    const { senderEmail, userEmail } = UserInfo();
    const [searchData, setSearchData] = useState([]);
    const accessToken = Token();

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        let searchData = event.target.value;
        if (accessToken && senderEmail && userEmail) {
            SearchList(accessToken, userEmail, senderEmail, searchData, (error, data) => {
                if (error) {
                    console.log(error);
                } else {
                    //console.log("Search Callback", data);
                    setSearchData(data);
                }
            });
        }
        setShowSearchList(true);
    };

    const handleSearch = (selectedItem) => {
        //console.log(selectedItem);
        // setSearchTerm(selectedItem.name);
        setShowSearchList(false);
        handleSelectedObjectId(selectedItem.obj_id);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchList.current && !searchList.current.contains(event.target)) {
                setShowSearchList(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchList]);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .MuiTooltip-tooltip`]: {
            maxHeight: 'none',
            minHeight: '50px',
            padding: '10px',
            fontSize: '14px',
            whiteSpace: 'pre-line',
        },
    });

    return (
        <>
            <Stack spacing={2} marginTop="8px" padding="8px">
                <TextField
                    label="Active request and orders"
                    id="search"
                    size="small"
                    className="textField"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                {showSearchList && searchTerm && searchData.length > 0 && (
                    <List
                        ref={searchList}
                        sx={{
                            maxWidth: "100%",
                            backgroundColor: 'white',
                            boxShadow: "0.1px 0.1px 5px #d1cfcf",
                            borderRadius: "12px",
                            maxHeight: "220px",
                            overflow: "auto"
                        }}
                    >
                        {searchData.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <CustomTooltip title={`${item.name}\n${item.type}\n${item.supplier_name}`}>
                                    <ListItemButton
                                        sx={{
                                            fontSize: "16px",
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}
                                        onClick={() => handleSearch(item)}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    <span>{truncateText(item.name, 10)}</span>
                                                    <span style={{ paddingLeft: '10px' }}>{truncateText(item.supplier_name, 12)}</span>
                                                    <span style={{ fontWeight: "bold", paddingLeft: '2px' }}>{truncateText(item.type, 10)}</span>
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </CustomTooltip>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Stack>
        </>
    )
}

export default Search;
