/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import { Card, CardContent, Typography, Box, Stack } from '@mui/material';
import useLoader from '../../utils/UseLoader';
import Loader from '../../Loader/Loader';
import UIText from '../../utils/TextResources';

const Suggestions = ({ suggestionData, handleSelectedObjectId }) => {
    const isLoading = useLoader();
    const [showCard, setShowCard] = useState(false);

    useEffect(() => {
        if (suggestionData) {
            setShowCard(true);
        }
    }, [suggestionData]);

    const handleSelectedSuggestion = (suggestion) => {
        setShowCard(false);
        handleSelectedObjectId(suggestion.obj_id);
    }

    return (
        <>
            {isLoading && !suggestionData && (
                <Box sx={{ display: 'flex', flexDirection: "row", margin: "8px" }}>
                    <Loader loader={{ size: "26px" }} />
                    <Typography sx={{ padding: "2px", paddingLeft: "8px", color: "#959595" }}>{UIText.suggestions.searching}</Typography>
                </Box>
            )}

            {showCard && suggestionData && (
                <>
                    <Stack>
                        <Typography sx={{ paddingLeft: "8px", color: "#959595" }}>{UIText.suggestions.suggestion}</Typography>
                        {suggestionData.map((suggestion, index) => (
                            <Card
                                key={index}
                                sx={{
                                    maxWidth: "92%",
                                    margin: "8px",
                                    border: "1px solid #dddddd",
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "#e9e9e9",
                                        transition: "background-color 0.3s, color 0.3s"
                                    }
                                }}
                                onClick={() => handleSelectedSuggestion(suggestion)}
                            >
                                <CardContent>
                                    <Typography sx={{ fontWeight: "bold" }}>{suggestion.name}</Typography>
                                    <Typography sx={{ paddingTop: "8px", paddingBottom: "8px" }}>{suggestion.type}</Typography>
                                    <Typography>{suggestion.supplier_name}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Stack>

                    {suggestionData && suggestionData.length === 0 && (
                        <Typography sx={{ padding: "8px", color: "#7F7F7F", fontSize: "14px" }}>Suggestions not available</Typography>
                    )}
                </>
            )}
        </>
    )
}

export default Suggestions;
