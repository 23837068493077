/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

/* global document, Office, module, require */
const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
  },
});

const title = "Axya Outlook Add-in";

const rootElement = document.getElementById("container");
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {

  // Office.context.mailbox.addHandlerAsync(Office.EventType.SelectedItemsChanged, getNewMessageProperties, (asyncResult) => {
  //   if (asyncResult.status === Office.AsyncResultStatus.Failed) {
  //     console.log(asyncResult.error.message);
  //     return;
  //   }

  //   console.log("Event handler added for the SelectedItemsChanged event.");
  // });


  // function getNewMessageProperties() {
  //   //location.reload();
  //   window.location.reload();
  // }

  root.render(
    <ThemeProvider theme={theme}>
      <App title={title} />
    </ThemeProvider>
  );
});

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    root.render(NextApp);
  });
}
