/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { constants } from "./Constants";
import CryptoJS from "crypto-js";

export const Token = () => {
    let decryptedToken;
    const encryptedToken = localStorage.getItem("axay_access");

    if (encryptedToken) {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, constants.secretKey);
        decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
    } else {
        decryptedToken = null;
    }

    return decryptedToken;
};