/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';

const UserInfo = () => {
    const [userDisplayName, setUserDisplayName] = useState(undefined);
    const [userEmailAddress, setUserEmailAddress] = useState(undefined);
    const [senderName, setsenderName] = useState(undefined);
    const [senderEmail, setsenderEmail] = useState(undefined);
    const [userEmail, setuserEmail] = useState(undefined);
    const [userName, setuserName] = useState(undefined);
    const [ccEmails, setccEmails] = useState(undefined);
    const [toEmails, settoEmails] = useState(undefined);
    const [mailBody, setmailBoby] = useState(undefined);
    const [attachments, setattachments] = useState(undefined);
    const [attachmentNames, setattachmentNames] = useState(undefined);
    const [DateTime, setdateTime] = useState(undefined);
    const [message_id, setMessage_id] = useState(undefined);
    const [thread_id, setthread_id] = useState(undefined);
    const [title, settitle] = useState(undefined);

    useEffect(() => {
        const item = Office.context.mailbox.item;
        const displayName = Office.context.mailbox.userProfile.displayName;
        const emailAddress = Office.context.mailbox.userProfile.emailAddress;
        setUserDisplayName(displayName);
        setUserEmailAddress(emailAddress);

        // Message id
        let itemId = item.itemId;
        const encodeitemid = encodeURIComponent(itemId);
        setMessage_id(encodeitemid);

        // sender Name
        const senderName = item.sender.displayName;
        setsenderName(senderName);

        // sender email
        const senderemailAddress = item.sender.emailAddress;
        // console.log(senderemailAddress);
        setsenderEmail(senderemailAddress);

        // user email
        const usermail = Office.context.mailbox.userProfile.emailAddress;
        // console.log(userEmail);
        setuserEmail(usermail);

        // user name
        const username = Office.context.mailbox.userProfile.displayName;
        setuserName(username);

        // All cc emails 
        const msgCc = item.cc;
        // Define arrays to store and emails 
        let ccEmails = [];
        for (let i = 0; i < msgCc.length; i++) {
            ccEmails.push(msgCc[i].emailAddress);
        }
        // console.log("CC Emails:", ccEmails);
        setccEmails(ccEmails);

        // All to emails
        const toRecipients = item.to;
        // Define an array to store email addresses
        const toEmails = [];

        toRecipients.forEach(recipient => {
            toEmails.push(recipient.emailAddress);
        });
        // console.log("To Emails:", toEmails);
        settoEmails(toEmails);

        // get mail body as text
        item.body.getAsync(Office.CoercionType.Text, function (result) {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
                let emailBody = result.value;

                // Function to remove quoted text from email body
                function removeQuotedText(text) {
                    // This regex tries to match common email quoting patterns.
                    return text.replace(/On .* at .* wrote:.*|From:.*Sent:.*To:.*Cc:.*Subject:.*|-----Original Message-----|----- Forwarded message -----/gs, '').trim();
                }

                // Remove URLs
                emailBody = emailBody.replace(/https?:\/\/\S+/g, '');

                // Remove HTML tags
                emailBody = emailBody.replace(/<[^>]*>/g, '');

                // Replace special HTML entities
                emailBody = emailBody.replace(/&nbsp;/g, ' ')
                    .replace(/&amp;/g, '&')
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&quot;/g, '"')
                    .replace(/&#39;/g, "'");

                // Remove any remaining HTML tags
                emailBody = emailBody.replace(/<[^>]*>/g, '');

                // Remove quoted text (previous emails in a reply chain)
                emailBody = removeQuotedText(emailBody);

                // Replace multiple spaces and new lines with a single space
                emailBody = emailBody.replace(/\s+/g, ' ').trim();

                //console.log(emailBody);
                setmailBoby(emailBody);
            } else {
                console.error("Failed to get the mail body:", result.error.message);
            }
        });


        //get attachments names
        const attachments = item.attachments;
        //setattachments(attachments);
        const attachmentsName = [];
        attachments.forEach(function (attachment) {
            attachmentsName.push(attachment.name);
        });

        // Now attachmentNames contains all attachment file names
        // console.log("Attachment Names:", attachmentsNames);
        setattachmentNames(attachmentsName);

        if (item.attachments.length >= 1) {
            const attachment = item.attachments[0];
            item.getAttachmentContentAsync(attachment.id, (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    const attachmentBase64 = result.value.content;
                    // console.log(attachmentBase64);
                    //console.log(attachment.contentType);
                    const data = { base64: attachmentBase64, contentType: attachment.contentType, name: attachment.name }
                    setattachments(data);
                } else {
                    console.log(result.error);
                }
            });
        } else {
            console.log("no attattachment found")
        }

        // get Creation date and time 
        const dateTime = item.dateTimeCreated;
        const creationDateTime = new Date(dateTime);
        const formattedCreationDateTime = creationDateTime.toISOString();
        setdateTime(formattedCreationDateTime);

        const threadid = item.conversationId;
        const encodethreadid = encodeURIComponent(threadid)
        //console.log("encode thread id", encodethreadid);
        setthread_id(encodethreadid);

        const subject = item.subject;
        settitle(subject);
    }, []);

    return { userDisplayName, userEmailAddress, senderName, senderEmail, userEmail, userName, ccEmails, toEmails, mailBody, attachments, attachmentNames, DateTime, message_id, thread_id, title };
}

export default UserInfo;