/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Stack, Button, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import UserInfo from '../../utils/UsersInfo';
import UIText from '../../utils/TextResources';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const Navigate = useNavigate();
    const { userDisplayName, userEmailAddress } = UserInfo();

    const handleLogout = () => {
        localStorage.removeItem("axay_access");
        localStorage.removeItem("axay_refresh");

        Navigate("/");
    }

    return (
        <>
            <Stack spacing={2} sx={{ paddingLeft: "4px", marginTop: "12px" }}>
                <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FiberManualRecordIcon style={{ color: "#21E4A6" }} />
                    <Typography variant="body1" sx={{ marginLeft: "10px", fontWeight: "600" }}>{UIText.logout.connected}</Typography>
                </Stack>
                <Stack>
                    <Typography sx={{ fontSize: "13px", paddingLeft: "6px" }}>{userDisplayName} &lt;{userEmailAddress}&gt;</Typography>
                </Stack>
                <Stack sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                    <Button
                        variant="text"
                        sx={{
                            textTransform: "none",
                            marginRight: "12px"
                        }}
                        onClick={handleLogout}
                    >
                        {UIText.logout.logoutText}
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}

export default Logout;
