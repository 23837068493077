/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { constants } from '../utils/Constants';

export const UnSyncMessage = (accessToken, thread_id, callback) => {
    if (thread_id != undefined) {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/email/unsync/${thread_id}/`,
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/json",
                "Authorization": `JWT ${accessToken}`
            },
        };

        $.ajax(settings)
            .done(function (response) {
                //console.log(response);
                callback(null, response);
            })
            .fail(function (error) {
                console.error(error);
                callback(error, null);
            });
    } else {
        console.log("UnSyncMessage API not call due to undefined thread_id parameter");
    }
}
