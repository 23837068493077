/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react'
import {
    Box,
    CircularProgress,
    circularProgressClasses
} from '@mui/material';

const Loader = ({ loaderOverlay, loader }) => {
    return (
        <>
            <div style={loaderOverlay}>
                <Box sx={{ position: "relative" }}>
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: (theme) =>
                                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={loader.size}
                        thickness={4}
                        value={100}
                    />
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                            animationDuration: '550ms',
                            position: 'absolute',
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        size={loader.size}
                        thickness={4}
                    />
                </Box>
            </div>
        </>
    )
}

export default Loader;
