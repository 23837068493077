/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { constants } from '../utils/Constants';

export const SyncAttachments = (accessToken, attachments, message_id, callback) => {
    if (accessToken && attachments != undefined && message_id != undefined) {
        //console.log(attachments);
        const byteCharacters = atob(attachments.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: attachments.contentType });

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `JWT ${accessToken}`);

        const formdata = new FormData();
        formdata.append("file", blob, attachments.name);
        formdata.append("message_id", message_id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch(`${constants.BASE_URL}/api/v1/email/sync/attachments/`, requestOptions)
            .then(response => response.text())
            .then(result => {
                // console.log(result);
                callback(null, result);
            })
            .catch(error => {
                console.error(error);
                callback(error, null);
            });
    } else {
        console.log("SyncAttachments API not call due to undefined attachments parameter");
    }
}
