/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { constants } from '../utils/Constants';

export const GetMessage = (thread_id, accessToken, callback) => {
    if (thread_id != undefined && accessToken) {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/email/messages/${thread_id}/`,
            "method": "GET",
            "timeout": 0,
            "headers": {
                "Authorization": `JWT ${accessToken}`
            },
        };

        $.ajax(settings)
            .done(function (response) {
                //console.log(response);
                if (response.id) {
                    callback(null, response);
                } else {
                    callback(response);
                }
            })
            .fail(function (error) {
                // console.error(error);
                callback(error.status);
            });
    } else {
        console.log("GetMessage API not call due undefined thread_id parameter");
    }
}