/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../views/Login/Login";
import Header from "../views/Header/Header";

function RouterApp() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/header" element={<Header />} />
            </Routes>
        </Router>
    )
}

export default RouterApp;
