/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { constants } from "../utils/Constants";

export const SearchList = (accessToken, userEmail, senderEmail, searchData, callback) => {
    if (accessToken && userEmail != undefined && senderEmail != undefined && searchData) {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/email/search/?term=${searchData}&emails=${userEmail},${senderEmail}`,
            "method": "GET",
            "timeout": 0,
            "headers": {
                "Authorization": `JWT ${accessToken}`
            },
        };

        $.ajax(settings)
            .done(function (response) {
                // console.log(response);
                callback(null, response);
            })
            .fail(function (error) {
                console.error(error);
                callback(error, null);
            });
    } else {
        console.log("SearchList API not call due undefined senderEmail & userEmail parameters");
    }
}