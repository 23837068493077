/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import "../../assets/css/main.css";
import Router from "../taskpane/components/routers/Routers";

const App = () => {
  return (
    <>
      <Router />
    </>
  );
};

export default App;
