/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { constants } from "../utils/Constants";
import CryptoJS from "crypto-js";

// Function to validate credentials
const validateCredentials = (code) => {
    return new Promise((resolve, reject) => {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/auth/exchangeOTT/`,
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/json"
            },
            "data": JSON.stringify({
                "ott": code
            }),
        };

        $.ajax(settings)
            .done(function (response) {
                //console.log(response);

                if (response.access) {
                    let accessToken = response.access;
                    let refreshToken = response.refresh;
                    const encryptedToken = CryptoJS.AES.encrypt(accessToken, constants.secretKey).toString();
                    const encryptedRefreshToken = CryptoJS.AES.encrypt(refreshToken, constants.secretKey).toString();
                    localStorage.setItem("axay_access", encryptedToken);
                    localStorage.setItem("axay_refresh", encryptedRefreshToken);
                    resolve(); // Resolve the promise if credentials are valid
                } else {
                    reject(new Error("Invalid Credentials")); // Reject with an Error object if credentials are invalid
                }
            })
            .fail(function (jqXHR) {
                let errorMessage = "Error";
                if (jqXHR.responseJSON && jqXHR.responseJSON.error) {
                    errorMessage += ": " + jqXHR.responseJSON.error;
                } else {
                    errorMessage += ": " + jqXHR.statusText;
                }
                reject(new Error(errorMessage)); // Reject with an Error object containing the error message
            });
    });
};

export default validateCredentials;