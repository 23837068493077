/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React from 'react';
import {
    Stack,
    Typography,
    Divider
} from '@mui/material';
import UIText from '../../utils/TextResources';

const Supplier = ({ messages }) => {
    let itemsList;
    if (messages) {
        itemsList = messages.items;
        //console.log("itemsList: ", itemsList);
    }

    return (
        <>
            {messages && (
                <><Stack>
                    <Typography sx={{ color: "#959595", paddingTop: "6%" }}>{UIText.items.itemsList}</Typography>

                    {itemsList.map((item, index) => (
                        <Stack key={index} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "2%" }}>
                            <Typography>{item.name}</Typography>
                            <Typography>{item.count}</Typography>
                        </Stack>
                    ))}

                    {itemsList && itemsList.length === 0 && (
                        <Typography sx={{ paddingTop: "8px", color: "#7F7F7F", fontSize: "14px" }}>{UIText.items.notItems}</Typography>
                    )}
                </Stack>
                    <Divider sx={{ maxWidth: "98%", marginTop: "4%", marginBottom: "4%" }} />
                </>
            )}

        </>
    )
}

export default Supplier;
